import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/auth/loginActions";
const UserDropdown = (props) => {
  return (
    <DropdownMenu right>
      {/* <DropdownItem tag="a" href="#">
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem> */}
      {/* {/ <DropdownItem divider /> /} */}
      <DropdownItem
        tag="a"
        onClick={() => {
          props.logoutUser();
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    console.log("555",props);
    if (props.user != null) {
      return {
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        user_type: props.user.user_type,
      };
    }
    return null;
  }

  state = {
    first_name: "",
    last_name: "",
    user_type: "",
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }
  componentDidUpdate() {
    console.log("name", this.state);
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    console.log("hello5", this)
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <div className="re-top_nave_block">
          <div><Icon.User/></div>
          <div className="re-top_nave_userdetail">
            <div>{this.state.first_name} {this.state.last_name}</div>
            <div>Private Driver AS - Ride Flex</div>
          </div>
        </div>
        <div className="re-top_nave_Login_block">
          <div>Help</div>
          <div 
            onClick={() => {
              this.props.logoutUser();
            }}
          >Logout</div>
        </div>
        {/* <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link"> */}
            {/* <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.state.first_name} {this.state.last_name}
              </span>
              <span className="user-status">{this.state.user_type}</span>
            </div> */}
            {/* <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span> */}
          {/* {/ {/ </DropdownToggle> */} 
          <UserDropdown {...this.props} />
         {/* {/ </UncontrolledDropdown>  /}  */}
      </ul>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.login.values,
  errors: state.errors,
});
export default connect(mapStateToProps, { logoutUser })(NavbarUser);