import React, { Component } from 'react';
import {
  Button,
  Progress,
  Input,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import Spinner from '../../../components/@vuexy/spinner/Fallback-spinner';
import classnames from 'classnames';
import ReactPaginate from 'react-paginate';
import { history } from "../../../history";
import ic_refresh from "../../../assets/img/rides/ic_refresh.svg"
import { connect } from 'react-redux';
import {
  Edit,
  Trash,
  ChevronDown,
  Plus,
  ChevronsLeft,
  ChevronsRight,
  Search,
} from 'react-feather';
import Sidebar from './Sidebar';
import Chip from '../../../components/@vuexy/chips/ChipComponent';
import '../../../assets/scss/plugins/extensions/react-paginate.scss';
import '../../../assets/scss/pages/data-list.scss';
import ic_more_details from "../../../assets/img/rides/ic_more_details.svg"
import Vector from "../../../assets/img/rides/Vector.svg"
//import '../../../assets/scss/custom.css';
import message from '../../../language/message';

import {
  getData,
  getInitialData,
  filterData,
  getId,
  changeStatus,
} from '../../../redux/actions/vehicle';
import { loadingTrue, loadingFalse } from '../../../redux/actions/loading';
import DotSpinner from "../../../components/@vuexy/spinner/dot-spinner"

const chipColors = {
  DEACTIVATED: 'danger',
  ACTIVE: 'success',
  PENDING: 'warning',
  canceled: 'danger',
};

const selectedStyle = {
  rows: {
    selectedHighlighStyle: {
      backgroundColor: 'rgba(115,103,240,.05)',
      color: '#CD9900 !important',
      boxShadow: '0 0 1px 0 #CD9900 !important',
      '&:hover': {
        transform: 'translateY(0px) !important',
      },
    },
  },
};

const ActionsComponent = (props) => {
  return (
    <div className="data-list-action">
      <Edit
        className="cursor-pointer mr-1"
        size={20}
        onClick={() => {
          return props.currentData(props.row);
        }}
      />
      <Trash
        className="cursor-pointer"
        size={20}
        onClick={() => {
          props.deleteRow(props.row);
        }}
      />
    </div>
  );
};
const StatusComponent = (props) => {
  return (
    <div>
      <Button.Ripple
        onClick={() => props.changeStatus(props.row)}
        className="toggle"
        color={
          props.row.status == 'PENDING'
            ? 'warning'
            : props.row.status == 'ACTIVE'
              ? 'success'
              : 'danger'
        }
        disabled={(props.status_loading && props.row._id == props.status_loading_id) || props.status_loading || props.row.status == 'PENDING'}
      >
        {props.status_loading && props.row._id == props.status_loading_id ? <DotSpinner /> : props.row.status}
      </Button.Ripple>

      {/* <Toggle
        defaultChecked={props.row.status == 'ACTIVE' ? true : false}
        className="switch-danger"
        disabled={props.row.status == 'PENDING' ? true : false}
        onChange={() => props.changeStatus(props.row)}
      /> */}
    </div>
  );
};

const CustomHeader = (props) => {
  return (
    <div className="data-list-header d-flex justify-content-between flex-wrap">
      <div className="actions-left d-flex flex-wrap">
        <Button
          className="add-new-btn"
          color="primary"
          onClick={() => {
            props.getId('');
            props.handleSidebar(true, true);
          }}
          outline
        >
          <Plus size={15} />
          <span className="align-middle">Add Vehicle</span>
        </Button>
      </div>
      <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
        {typeof props.start != 'undefined' &&
          typeof props.end != 'undefined' &&
          typeof props.total != 'undefined' ? (
          <UncontrolledDropdown className="data-list-rows-dropdown mr-1 d-md-block d-none">
            <DropdownToggle color="" className="sort-dropdown">
              <span className="align-middle mx-50">
                {`${props.start} - ${props.end} of ${props.total}`}
              </span>
              <ChevronDown size={15} />
            </DropdownToggle>
            <DropdownMenu tag="div" right>
              <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(4)}>
                4
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(10)}>
                10
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(15)}>
                15
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => props.handleRowsPerPage(20)}>
                20
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : null}
        <div className="filter-section">
          <Input
            placeholder='search'
            type="text"
            value={props.value}
            onChange={(e) => props.handleFilter(e)}
          />
        </div>
      </div>
    </div>
  );
};

class Vehicle extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.vehicles.data.length !== state.data.length ||
      state.currentPage !== props.parsedFilter.page ||
      state.status !== props.vehicles.status
    ) {
      return {
        data: props.vehicles.data,
        totalPages: props.vehicles.totalPages,
        totalRecords: props.vehicles.totalRecords,
        sortIndex: props.vehicles.sortIndex,
        start: props.vehicles.start,
        end: props.vehicles.end,
        status: props.vehicles.status,
        heading: props.vehicles.heading,
        sub_heading: props.vehicles.sub_heading
        // currentPage: parseInt(props.parsedFilter.page) - 1,
        // rowsPerPage: parseInt(props.parsedFilter.perPage),
        // totalRecords: props.vehicles.totalRecords,
        // sortIndex: props.dataList.sortIndex
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  state = {
    data: [],
    totalPages: 0,
    currentPage: 0,
    status: [],
    columns: [
      {
        name: 'Model',
        selector: 'model',
        sortable: true,
        minWidth: "400px",
        maxWidth: "400px"
      },
      {
        name: 'Service class',
        selector: 'service_class.name',
        sortable: true,
        cell: (row) => <div style={{ backgroundColor: row.service_class.color, padding: "5px", borderRadius: "3px", color: "white" }}>{row.service_class.name}</div>,
      },

      {
        name: 'License plate',
        selector: 'plate_number',
        sortable: true,
      },
      // {
      //   name: 'Production year',
      //   selector: 'year',
      //   sortable: true,
      // },

      // {
      //   name: 'Color',
      //   selector: 'color',
      //   sortable: true,
      // },
      {
        name: 'Status',
        sortable: true,
        cell: (row) => (
          <StatusComponent
            row={row}
            getData={this.props.getData}
            parsedFilter={this.props.parsedFilter}
            currentData={this.handleCurrentData}
            deleteRow={this.handleDelete}
            changeStatus={this.handleChangeStatus}
            status_loading={this.props.status_loading}
            status_loading_id={this.props.status_loading_id}
          />
        ),
      },
    ],
    allData: [],
    value: '',
    rowsPerPage: 4,
    sidebar: false,
    currentData: null,
    selected: [],
    totalRecords: 0,
    sortIndex: [],
    addNew: '',
    heading: '',
    sub_heading: '',
    rotated: false,
  };

  thumbView = this.props.thumbView;












  componentDidUpdate(prevProps, prevState) {

    if (prevProps.status_loading !== this.props.status_loading) {
      let columns = [
        {
          name: 'Model',
          selector: 'model',
          sortable: true,
          minWidth: "400px",
          maxWidth: "400px"
        },
        {
          name: 'Service class',
          selector: 'service_class.name',
          sortable: true,
          cell: (row) => <div style={{ backgroundColor: row.service_class.color, padding: "5px", borderRadius: "3px", color: "white" }}>{row.service_class.name}</div>,
        },

        {
          name: 'License plate',
          selector: 'plate_number',
          sortable: true,
        },
        // {
        //   name: 'Production year',
        //   selector: 'year',
        //   sortable: true,
        // },

        // {
        //   name: 'Color',
        //   selector: 'color',
        //   sortable: true,
        // },
        {
          name: 'Status',
          sortable: true,
          cell: (row) => (
            <StatusComponent
              row={row}
              getData={this.props.getData}
              parsedFilter={this.props.parsedFilter}
              currentData={this.handleCurrentData}
              deleteRow={this.handleDelete}
              changeStatus={this.handleChangeStatus}
              status_loading={this.props.status_loading}
              status_loading_id={this.props.status_loading_id}
            />
          ),
        },
      ]
      this.setState({ columns });
    }
  }











  componentDidMount = async () => {
    console.log('this.props.parsedFilter', this.props.parsedFilter)

    await this.props.loadingTrue();
    await this.props.getData(this.props.parsedFilter);
    await this.props.loadingFalse();
  };
  handleChangeStatus = (value) => {
    this.props.changeStatus({
      id: value._id,
      working_status: value.status,
    });
  };

  handleFilter = (e) => {
    this.setState({ value: e.target.value, currentPage: 1 });
    this.props.filterData(e.target.value);
  };

  handleRowsPerPage = async (value) => {
    let { parsedFilter, getData } = this.props;
    let page = parsedFilter.page !== undefined ? parsedFilter.page : 1;
    // history.push(`/data-list/list-view?page=${page}&perPage=${value}`);
    this.setState({ rowsPerPage: value, currentPage: 1 });
    await this.props.loadingTrue();
    await getData({ page: 1, limit: value, search_data: this.state.value });
    await this.props.loadingFalse();
  };

  handleSidebar = (boolean, addNew = false) => {
    console.error('handleSidebar', addNew);
    this.setState({ sidebar: boolean });
    if (addNew === true) this.setState({ currentData: null, addNew: true });
  };

  handleDelete = (row) => {
    this.props.deleteData(row);
    this.props.getData(this.props.parsedFilter);
    if (this.state.data.length - 1 === 0) {
      let urlPrefix = this.props.thumbView
        ? '/data-list/thumb-view/'
        : '/data-list/list-view/';
      history.push(
        `${urlPrefix}list-view?page=${parseInt(
          this.props.parsedFilter.page - 1
        )}&perPage=${this.props.parsedFilter.perPage}`
      );
      this.props.getData({
        page: this.props.parsedFilter.page - 1,
        perPage: this.props.parsedFilter.perPage,
      });
    }
  };

  handleCurrentData = (obj) => {
    this.setState({ currentData: obj });
    this.handleSidebar(true);
  };

  handlePagination = async (page) => {
    console.log('here', page.selected);
    this.setState({
      loading: true,
    });
    let { parsedFilter, getData, loadingTrue, loadingFalse } = this.props;
    let perPage =
      this.state.rowsPerPage !== undefined ? this.state.rowsPerPage : 4;
    await loadingTrue();
    await getData({
      page: page.selected + 1,
      limit: perPage,
      search_data: this.state.value,
    });
    await loadingFalse();
    // var pageNumber = page.selected + 1;
    // console.log("number ",parseInt(pageNumber));
    this.setState({ currentPage: page.selected + 1, loading: false });
    // console.log("page selected",page.selected);
    // console.log("current page",this.state.currentPage);
  };

  handleImageClick = () => {
    this.setState((prevState) => ({
      rotated: true,
    }))
    setTimeout(() => {
      this.setState((prevState) => ({
        rotated: false,
      }))
    }, 1000)
  };

  render() {
    let {
      columns,
      data,
      allData,
      totalPages,
      value,
      rowsPerPage,
      currentData,
      sidebar,
      totalRecords,
      sortIndex,
      start,
      end,
      rotated
    } = this.state;
    return (
      <Card>
        <div className='re-component_heading_block'>
          <div className='row' style={{ alignItems: "center" }}>
            <div className='col-10'>
              <div className='re-component_heading_title'>Vehicles</div>
              <div className='re-component_heading_text'>Vehicles listed below with there details and status.</div>
            </div>
            <div className='col-2 re-add_chauffeurs_button'>
              <div
                onClick={this.handleImageClick}><img className={`loader ${rotated ? 'rotate-90' : ''}`} src={ic_refresh} /></div>
              <div className="" onClick={() => history.push("/vehicles/add")}>Add Vehicle</div>
            </div>
          </div>
        </div>
        <div className='re-Chauffeurs_filter_block'>
          <div className='row' style={{ alignItems: "center" }}>
            {console.log("this.state", this.state)}
            <div className='col-8'>{this.state.totalRecords < 10 ? <> 0{this.state.totalRecords} Offers</> : <>{this.state.totalRecords} Offers</>}</div>
            <div className='col-4'>
              <div className="re-Chauffeurs_search_block">
                <div className="re-Chauffeurs_search_icon"><Search /></div>
                <div className="re-Chauffeurs_search_input">
                  <input placeholder="Search chauffeur"
                    value={this.state.searchDriver} // Set input value from state
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="re-Chauffeurs_search_button">Serach</div>
              </div>
            </div>
          </div>
        </div>
        {this.props.loading ? (
          <Spinner />
        ) : (
          <CardBody>
            <div
              className={`data-list ${this.props.thumbView ? 'thumb-view' : 'list-view'
                }`}
            >
              {this.state?.data?.length <= 0 ? (
                <>
                  <div className='row card_block_offer no_data_screen' style={{ border: "0px solid #D9D9D9" }}>
                    <div className="text-center border w-100 ">
                      <img
                        alt="No data"
                        className="mb-2 mt-5"
                        src="https://pd-dev-global.s3-eu-west-1.amazonaws.com/app_images/ic_default_file.png"
                      />
                      <h4 className="no_data_Found">No Data Found</h4>
                      {/* <p className="mb-5">{this.state.sub_heading}</p> */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="re-Chauffeurs_block re-Vehicles heading">
                  <div className="row">
                    <div className="col-2">
                      <div>License Plate</div>
                    </div>
                    <div className="col-2">
                      <div>Model</div>
                    </div>
                    <div className="col-2">
                      <div>Color</div>
                    </div>
                    <div className="col-2">
                      <div>Production Year</div>
                    </div>
                    <div className="col-1">
                      <div className="">Service class</div>
                    </div>
                    <div className="col-2">
                      Status
                    </div>
                    <div className="col-2">
                      Action
                    </div>
                  </div>
                </div>
              )}

              {console.log("888888", this.state)}
              {this.state.data.map((value, index) => (
                <div className="re-Chauffeurs_block re-Vehicles">
                  <div className="row">
                    <div className="col-2">
                      <div>{value.plate_number}</div>
                    </div>
                    <div className="col-2">
                      <div>{value.vehicle_model.model}</div>
                    </div>
                    <div className="col-3">
                      {/* <div>{value.service_class.name}</div> */}
                      black
                    </div>
                    <div className="col-2">
                      <div>{value.vehicle_model.year}</div>
                    </div>
                    <div className="col-1">
                      <div className="re-Vehicles_service_button" style={{ background: `${value.service_class.color}` }}>{value.service_class.name}</div>
                    </div>
                    <div className="col-2">
                      {value.status == "ACTIVE" ?
                        <div className="re-Chauffeurs_status_hold live"><div></div>Live</div> :
                        <div className="re-Chauffeurs_status_hold"><div></div>On hold</div>
                      }
                    </div>
                    <div className="col-2">
                      <div className='offer_one_extra_More'
                      // onClick={() => this.handleAssignDriverUserDetail(index)}
                      >
                        <div><img src={ic_more_details} /></div>
                        <div>More Details</div>
                        <div><img style={{ width: "13px", height: "13px", marginLeft: "1rem" }} src={Vector} /></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Sidebar
                show={sidebar}
                data={currentData}
                updateData={this.props.updateData}
                addData={this.props.addData}
                handleSidebar={this.handleSidebar}
                thumbView={this.props.thumbView}
                getData={this.props.getData}
                dataParams={this.props.parsedFilter}
                addNew={this.state.addNew}
              />
              <div
                className={classnames('data-list-overlay', {
                  show: sidebar,
                })}
                onClick={() => this.handleSidebar(false, true)}
              />
            </div>
          </CardBody>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    vehicles: state.vehicle,
    loading: state.loading.loading,
    status_loading: state.vehicle.loading,
    status_loading_id: state.vehicle.loading_id
  };
};

export default connect(mapStateToProps, {
  getData,
  getInitialData,
  filterData,
  getId,
  changeStatus,
  loadingTrue,
  loadingFalse,
})(Vehicle);
